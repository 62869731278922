<template>
  <div>
    <v-flex
      d-flex
      justify-center
      align-center
      class="overflow-y-auto"
      :style="
        `max-height: ${maxHeight}vh; min-height: ${
          autoHeight == true ? 'auto' : `${minHeight}vh`
        };`
      "
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-flex>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: {
    autoHeight: {
      type: Boolean,
      default: false,
      required: false
    },
    minHeight: {
      type: Number,
      default: 80,
      required: false
    },
    maxHeight: {
      type: Number,
      default: 80,
      required: false
    }
  }
}
</script>
